<template>
  <div class="arrows">
    <button aria-label="Previous" class="prev" :class="{disabled: prevDisabled}" @click="$emit('prev')">
      <Arrow />
    </button>
    <button aria-label="Next" class="next" :class="{disabled: nextDisabled}" @click="$emit('next')">
      <Arrow />
    </button>
  </div>
</template>

<script>
import Arrow from '@/assets/img/arrow.svg'
export default {
  name: 'SliderArrows',
  props: {
    prevClass: { type: String, default: '' },
    nextClass: { type: String, default: '' },
    nextDisabled: { type: Boolean, default: false },
    prevDisabled: { type: Boolean, default: false }
  },
  components: { Arrow }
}
</script>

<style lang="scss" scoped>
.arrows {
  display: flex;
  justify-content: space-between;
}

.prev,
.next {
  display: inline-block;
  height: rem(27px);
  width: rem(27px);
  outline: none;
  transition: opacity 0.2s;

  svg {
    width: 100%;
    height: 100%;
  }
}

.prev {
  transform: rotate(180deg);

  &:hover:not(.disabled) {
    svg {
      animation: onHover 0.5s;
    }
  }
}

.next {
  &:hover:not(.disabled) {
    svg {
      animation: onHover 0.5s;
    }
  }
}

.disabled {
  opacity: 0.2;
}

@keyframes onHover {
  0% {
    transform: translate3d(0%, 0%, 0);
    opacity: 1;
  }

  33% {
    transform: translate3d(50%, 0%, 0);
    opacity: 0;
  }

  34% {
    transform: translate3d(-50%, 0%, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0%, 0%, 0);
    opacity: 1;
  }
}
</style>
