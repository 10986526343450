<template>
  <div class="progressbar">
    <div
      ref="progress"
      class="progress"
      :class="type"
      :style="{width: type === 'full' ? '100%' : `${100/slides}%`}"
    ></div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
export default {
  name: 'SliderProgress',
  props: {
    type: {
      type: String,
      default: 'slide' // 'full' or 'slide'
    },
    slides: {
      type: Number,
      default: 1
    }
  },
  methods: {
    setProgress (progress, duration = 0) {
      const sliderProgress = this.type === 'full'
        ? { scaleX: progress }
        : { xPercent: 100 * (this.slides - 1) * progress }

      if (this.tl) {
        this.tl.kill()
        this.tl = undefined
      }

      if (duration) {
        this.tl = gsap.to(this.$refs.progress, { ...sliderProgress, duration, ease: 'ease-out' })
      } else {
        gsap.set(this.$refs.progress, sliderProgress)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.progressbar {
  width: 100%;
  height: 2px;
  background: $color-detail;
  overflow: hidden;
}

.progress {
  height: 2px;
  border-radius: 2px;
  background: $color-text-dark;

  &.full {
    transform: scaleX(0);
    transform-origin: center left;
  }
}
</style>
